<template>
  <ion-page class="page scrollable bg-transparent">
    <div class="d-flex justify-content-between align-items-center flex-wrap py-3">
      <div class="title">My Presets</div>

      <div class="ml-auto">
        <router-link :to="{ name: 'preset-creator' }"
          ><ion-button color="light" class="new-preset-btn">New Preset</ion-button></router-link
        >
      </div>
    </div>

    <presets-tabs class="my-2" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />
    <infinite-presets />
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import PresetsTabs from './components/PresetsTabs.vue';
import InfinitePresets from './components/InfinitePresets.vue';
import { Tab } from '@/shared/types/static-types';
import { presetsTabs } from '@/shared/statics/tabs';


@Options({
  name: 'PresetsPage',
  components: {
    PresetsTabs,
    InfinitePresets,
  },
})
export default class PresetsPage extends Vue {
  public tabs = presetsTabs;
  public currentTab: string = 'presets';
  public tabIndex = 0;

  public tabChanged({ value }: Tab) {
    const router = useRouter();
    this.currentTab = value;
    router.replace({ name: 'presets', query: { tab: value } });
  }

  public async created() {
    const router = useRouter();
    const document = useDocument();
    document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

    const tIndex = indexOf(
      this.tabs,
      find(this.tabs, (tab) => tab.value === router.currentRoute.value.query.tab)
    );
    if (router.currentRoute.value.query.tab && tIndex !== -1) {
      this.tabIndex = tIndex;
      this.currentTab = router.currentRoute.value.query.tab;
    }
  }
}
</script>
<style lang="sass" scoped>
.page
  overflow: unset !important

.infinite-home
  --background: transparent
  height: calc(100% - 58px - 40px)
  overflow: auto
  &::-webkit-scrollbar
    display: none

.new-preset-btn
  color: #214163
  font-size: 14px
  font-weight: bold

.title
  font-size: 24px
  font-weight: bold
</style>
