<template>
  <div>
    <div
      v-if="characterTemplates && characterTemplates.length"
      class="container"
      :class="characterTemplates.length === 1 ? '' : 'mx-3'"
    >
      <div class="row no-space">
        <template v-for="(template, index) in characterTemplates" :key="index">
          <div class="col-sm-6 col-md-6">
            <ion-card class="template-card-small py-3 px-3 d-flex">
              <img
                v-image
                class="img"
                :src="resizeUpload(get(template, 'info.cropProfilePicture', '/empty.png'), '80x80')"
              />

              <div class="ml-3 flex-grow-1">
                <div class="d-flex">
                  <div class="name">{{ template.template_title }}</div>
                  <div class="ml-auto">
                    <i
                      class="ti-more-alt clickable-item-hov"
                      style="font-size: 20px"
                      @click.stop="dropdownToggle($event, template.id)"
                    />
                  </div>
                </div>
                <div class="description">{{ truncateText(stripHtmlTags(template.template_desc), 120) }}</div>
              </div>
            </ion-card>
          </div>
        </template>
        <p
          v-if="nextPageExists"
          class="clickable-item-hov d-flex justify-content-center align-items-center"
          @click="requestLoadMore"
        >
          <span class="d-flex">
            <ion-skeleton-text v-if="isLoading" :animated="true" class="preset-field ml-1" />
            <ion-skeleton-text v-if="isLoading" :animated="true" class="preset-field ml-1" />
          </span>
          <span v-if="!isLoading"> Load more </span>
        </p>
      </div>
    </div>
    <div v-else class="mt-4">
      <div class="mb-2">
        <p>Use presets to save and reuse your character details.</p>
        <p>These are especially useful if you have a lot of characters with similar info.</p>
      </div>
      <div class="mt-3"><router-link :to="{ name: 'preset-creator' }">PRESS HERE</router-link> to make one!</div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { alertController } from '@ionic/vue';
import { deleteCharacterDraft, getCharactersDraft } from '@/shared/actions/characters';
import type { Paging } from '@/shared/types/static-types';
import { popovers, toast } from '@/shared/native';
import TemplatePopover from '@/shared/components/popovers/template-popover.vue';
import { getNextPage } from '@/shared/helpers/pagination';
import { stripHtmlTags } from '@/shared/utils/string';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateText } from '@/shared/utils/string';

@Options({
  name: 'InfinteTemplate',

  components: {},
})
export default class InfinteTemplate extends Vue {
  public characterTemplates: any = [];
  public paging: Paging | null = null;
  public isLoading = false;

  public stripHtmlTags = stripHtmlTags;
  public get = get;
  resizeUpload = resizeUpload;
  truncateText = truncateText;

  public async fetchCharactersTemplate(page = 1) {
    try {
      const { results, ...paging } = await getCharactersDraft('template', page);
      this.characterTemplates = results;
      this.paging = paging;
    } catch (e) {}
  }

  public get nextPageExists() {
    return !!this.paging?.next;
  }

  public async requestLoadMore() {
    try {
      this.isLoading = true;
      const { results, ...paging } = await getNextPage(this.paging!);
      this.characterTemplates = this.characterTemplates.concat(results);
      this.paging = paging;
    } catch (e) {
    } finally {
      this.isLoading = false;
    }
  }

  public async dropdownToggle(ev: CustomEvent, tempid: any) {
    const popover = await popovers.open(ev, TemplatePopover, { id: tempid });
    try {
      const { data } = await popover.onDidDismiss();
      if (data.value === 'delete') {
        const alert = await alertController.create({
          cssClass: '',
          header: 'Are you sure?',
          message: `Please confirm that you want to delete the selected template.`,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'text-secondary',
              id: 'cancel-button',
            },
            {
              text: 'Confirm',
              id: 'confirm-button',
              role: 'ok',
              cssClass: 'text-danger',
            },
          ],
        });
        await alert.present();

        const { role } = await alert.onDidDismiss();

        if (role === 'ok') {
          await deleteCharacterDraft(tempid);
          toast.show('Template deleted successfully', 'nonative', 'success');
          this.fetchCharactersTemplate();
        }
      }
    } catch (e) {}
  }

  mounted() {
    this.fetchCharactersTemplate();
  }
}
</script>

<style lang="sass" scoped>
.preset-field
  width: 384px
  height: 122px

.no-space
  &:not(:first-of-type)
    margin-left: 0
    margin-right: 0
    margin-bottom: 0

.no-space [class*='col-']:not(:first-child),
.no-space [class*='col-']:not(:last-child)
  padding-right: 0
  padding-left: 0

.template-card-small
  .name
    font-size: 20px
    font-weight: bold
  .description
    font-size: 12px
.img
  width: 76px
  height: 76px
  border-radius: 40px
  border: solid gray 0.1px !important
  object-fit: cover
</style>
