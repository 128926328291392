<template>
  <div>
    <router-link :to="{ name: 'character-creator', query: { presetId: id } }">
      <ion-item class="item-container" @click="editTemplate">
        <div class="menu-item d-flex align-items-center">
          <i class="ti-plus mr-2 menu-icon" />
          Use Template
        </div>
      </ion-item>
    </router-link>

    <router-link :to="{ name: 'preset-editor', params: { id: id } }">
      <ion-item class="item-container" @click="editTemplate">
        <div class="menu-item d-flex align-items-center">
          <i class="ti-marker-alt mr-2 menu-icon" />
          Edit Template
        </div>
      </ion-item>
    </router-link>

    <ion-item class="item-container clickable-item text-danger" @click="deleteTemplate('delete')">
      <div class="menu-item d-flex align-items-center">
        <i class="ti-trash mr-2 menu-icon" />
        Delete Template
      </div>
    </ion-item>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  instance: { type: Object, default: () => ({}) },
  id: { type: null, default: () => ({}) },
});

async function editTemplate() {
  await props.instance.dismiss();
}

async function deleteTemplate(value: string) {
  await props.instance.dismiss({ value });
}
</script>

<style scoped lang="sass">
.menu-icon
  width: 18px
  height: 18px

.item-container:hover
  background-color: #eeeeee !important
  div
    background-color: #eeeeee !important
</style>
